import React, {useEffect, useState} from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { ListItemText, Card, CssBaseline, Toolbar, ListItemIcon, Grid, AppBar, List, ListItem, ListItemButton, Typography } from '@mui/material';

import ConfirmationDialog from '../components/ConfirmationDialog';

import HowToVote from '@mui/icons-material/HowToVote';

import Api from '../Utils/Api';

export default function ElectionPositions(props){
//TODO: save startup state in localStorage and re-use if page reloaded

    const location = useLocation();
    const history = useHistory();

    const {name, role, election} = location.state;
    console.log("ElectionPositions");
    console.log(election);
    const [open, setOpen] = useState(false);
    const [currentPosition, setCurrentPosition] = useState({jamaatCode: '', auxiliary: '', electionType: '', attemptCount: 0});
    const [positions, setPositions] = useState([]);

    function handleListItemClicked(e, position){
        setCurrentPosition(position);
        setOpen(true);
    }

    function navigateNomination(elPos){
        history.push({
            pathname: '/nomination',
            state: {election: election, position: elPos, name: name, role: role}
        });    
    }

    function startVoting(e, election, position){
        Api.get("/election/startNomination/" +  position._id)
        .then( (res) => {
            //success
            const updatedPosition = res.data.electionPosition;
            navigateNomination(updatedPosition);
        }).catch((err) => {
            //failed
        });
    }

    useEffect(()=>{
        Api.get('/election/positions/' + election._id)
        .then((res)=>{
            for (let idx=0; idx < res.data.positions.length; idx++ ){
                const pos = res.data.positions[idx];
                if (pos.state === "nomination" || pos.state === "voting"){
                    navigateNomination(pos);
                }
            }

            setPositions(res.data.positions);
        }).catch((err)=>{

        })
    }, []);

    return <React.Fragment>
        <CssBaseline />
        <ConfirmationDialog open={open} title={"Start voting for " + currentPosition.name + "?"}
        message={`To start the nomination/voting process for ${election.jamaatCode} ${election.auxiliary} position ${currentPosition.title} (attempt: ${election.attemptCount + 1}) please click the Start button below.`}
        ok="Start" cancel="Cancel"
        onOk={(e)=> {startVoting(e, election, currentPosition)}}
        onCancel={() => {setOpen(false);}} />
        
        <AppBar color="default" sx={{padding:2}} position="sticky" >
            <Toolbar>
                <Grid container spacing={3} alignItems="center" justifyContent="space-evenly">
                    <Grid item xs={12} md={12}>
                        <Card>
                            <h2>{election.jamaatCode} {election.auxiliary} {election.electionType} election positions</h2>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        
                    </Grid>
                    <Grid item xs={12} md={3}>
                        
                    </Grid>
                    <Grid item xs={12} md={3}>
                        
                    </Grid>
                    <Grid item xs={12} md={3}>

                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
        <Toolbar>
            <Grid container spacing={2} paddingTop={1}>
                <Grid item xs={0} md={5}></Grid>
                <Grid item xs={12} md={12}>
                    <Typography variant='h6'>
                        Please select position to start the nomination/voting process
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12}>

                    <List>
                        {
                            positions.map( (position) => {
                                return <ListItem key={position._id} onClick={ (e)=>{ handleListItemClicked(e, position); } }>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <HowToVote />
                                        </ListItemIcon>
                                        <ListItemText primary={ position.name + " (" + position.title + ")" } />    
                                    </ListItemButton>
                                </ListItem>
                            })
                        }
                    </List>
                </Grid>
            </Grid>
        </Toolbar>
    </React.Fragment>
}