import React, {useState, useEffect} from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { ListItemText, Card, CssBaseline, Toolbar, ListItemIcon, Grid, AppBar, List, ListItem, ListItemButton, Typography } from '@mui/material';

import ConfirmationDialog from '../components/ConfirmationDialog';

import HowToVote from '@mui/icons-material/HowToVote';

import Api from '../Utils/Api';

export default function Elections(props){
//TODO: save startup state and re-use if page reloaded

    const location = useLocation();
    const history = useHistory();

    let {name, role} = ""; 
    let stateElections = [];
    if (location && location.state){
        let {name, role, stateElections} = location.state;
    }
    
    const [open, setOpen] = useState(false);
    const [elections, setElections] = useState([]);
    const [currentElection, setCurrentElection] = useState({jamaatCode: '', auxiliary: '', electionType: '', attemptCount: 0});

    function navigateToElection(election){
        history.push({
            pathname: '/quorum',
            state: {election: election}
        });    
    }

    function handleListItemClicked(e, election){
        setCurrentElection(election);
        setOpen(true);
    }

    function startElection(e, election){
        Api.post("/election/start", {electionId: election._id})
        .then( (res) => {
            //success
            navigateToElection(res.data.election);
        }).catch((err) => {
            //failed
            alert(err);
        });
    }

    useEffect(() => {
        if (stateElections && stateElections.length > 0){
            setElections(stateElections);
        }
        if (!stateElections || stateElections.length === 0){
            Api.get("/election/list")
            .then ((res) => {
                setElections(res.data.elections);
            })
            .catch((err) => {
                
            })
        };

        for(let idx=0; idx < elections.length; idx++){
            const election = elections[idx];
            if ((election.state === "in progress") || (election.state === "quorum")){
                navigateToElection(election);
            }
        }
    }, []);

    return <React.Fragment>
        <CssBaseline />
        <ConfirmationDialog open={open} title={"Start election for " + currentElection.jamaatCode + " " + currentElection.auxiliary + "?"}
        message={`To start the ${currentElection.auxiliary} ${currentElection.electionType} election for ${currentElection.jamaatCode} (attempt: ${currentElection.attemptCount + 1}) please click the Start button below.`}
        ok="Start" cancel="Cancel"
        onOk={(e)=> {startElection(e, currentElection)}}
        onCancel={() => {setOpen(false);}} />
        
        <AppBar color="default" sx={{padding:2}} position="sticky" >
            <Toolbar>
                <Grid container spacing={3} alignItems="center" justifyContent="space-evenly">
                    <Grid item xs={12} md={12}>
                        <Card>
                            <h2>{name} ({role})</h2>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        
                    </Grid>
                    <Grid item xs={12} md={3}>
                        
                    </Grid>
                    <Grid item xs={12} md={3}>
                        
                    </Grid>
                    <Grid item xs={12} md={3}>

                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
        <Toolbar>
            <Grid container spacing={2} paddingTop={1}>
                <Grid item xs={0} md={5}></Grid>
                <Grid item xs={12} md={12}>
                    <Typography variant='h6'>
                        Please select which election to start
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12}>

                    <List>
                        {
                            elections.map( (election) => {
                                return <ListItem key={election._id} onClick={ (e)=>{ handleListItemClicked(e, election); } }>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <HowToVote />
                                        </ListItemIcon>
                                        <ListItemText primary={election.jamaatCode + " " + election.auxiliary + " " + election.electionYear + " " + election.electionType + " elections"}  />    
                                    </ListItemButton>
                                </ListItem>
                            })
                        }
                    </List>
                </Grid>
            </Grid>
        </Toolbar>
    </React.Fragment>
}