import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import {Button, CssBaseline, TextField, Box, Grid, Typography, Link} from '@mui/material';
import api from '../Utils/Api';

//TODO: post member data and grab login cookies
//TODO: route to appropriate home page if already logged in?

function getEmptyValObj(){
    return {value: '', error: false, helperText: ''};
}

function clearError(obj){
    return {...obj, error: false, helperText: ''};
}

function setError(obj, err, helperText, setFunc){
    obj = {...obj};
    obj.error = err;
    obj.helperText = helperText;
    setFunc(obj);
}

export default function AssistantLogin(props) {
    const [electionCodeObj, setElectionCodeObj] = useState(getEmptyValObj());
    const [memberCodeObj, setMemberCodeObj] = useState(getEmptyValObj());
    const [sectionObj, setSectionObj] = useState(getEmptyValObj());
    const[memberNameObj, setMemberNameObj] = useState(getEmptyValObj());
    const [passwordObj, setPasswordObj] = useState(getEmptyValObj());

    const history = useHistory();

    function validate() {
        let err = false;
        setElectionCodeObj(clearError(electionCodeObj));
        setMemberCodeObj(clearError(memberCodeObj));
        setMemberNameObj(clearError(memberNameObj));
        setPasswordObj(clearError(passwordObj));

        if (!electionCodeObj.value){
            setError(electionCodeObj, true, "Required", setElectionCodeObj);
            err=true;
        }

        if (!sectionObj.value){
            setError(sectionObj, true, "Required", setSectionObj);
            err=true;
        }
    
        // if (!memberCodeObj.value){
        //     setError(memberCodeObj, true, "Required", setMemberCodeObj);
        //     err=true;
        // }
    
        if (!memberNameObj.value){
            setError(memberNameObj, true, "Required", setMemberNameObj);
            err=true;
        }

        if (!passwordObj.value){
            setError(passwordObj, true, "Required", setPasswordObj);
            err=true;
        }

        if (electionCodeObj.value){
            if (electionCodeObj.value.length !== 5){
                setError(electionCodeObj, true, "Must be 5 characters", setElectionCodeObj);
                err=true;
            }
        }

        // if (memberCodeObj.value){
        //     if (memberCodeObj.value.length > 5){
        //         setError(memberCodeObj, true, "Must be 5 numbers", setMemberCodeObj);
        //         err=true;
        //     }
        //     const memberCodeNumber = parseInt(memberCodeObj.value);
        //     if (memberCodeNumber < 20000 || memberCodeNumber > 70000){
        //         setError(memberCodeObj, true, "Invalid member code", setMemberCodeObj);
        //         err=true;
        //     }
        // }

        if (passwordObj.value){
            if (passwordObj.value.length > 20 || passwordObj.value.length < 3){
                setError(passwordObj, true, "Invalid password", setPasswordObj);
                err=true;
            }
        }
        return !err;
    }

    function handleLogin(){
        if (validate()){
            api.post("/login/assistant", {electionCode: electionCodeObj.value, section: sectionObj.value, name: memberNameObj.value, password: passwordObj.value}).then((res) => {
                const {name, role, election, section} = res.data;
                history.push({
                    pathname: 'assistant',
                    state: {name: name, role: role, election: election, section: section}
                })
            }).catch((err) => {
                alert(err);
            });

        }
    }
    

    return (
        <React.Fragment>
            <CssBaseline />
            <Box 
                        height="100vh"
                        component="form"
                        sx={{'& .MuiTextField-root': { width: '30ch'}}}
                        noValidate
                    >
                <Grid style={{height:"350px", width: "100%"}} container spacing={3} alignItems="center" justifyContent="space-evenly">

                    <Grid item xs={12} md={12}>
                        <Typography variant="h6">Get assistant credentials from Election Conductor to Login</Typography>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <div>
                            <TextField id="electionCode" error={electionCodeObj.error} helperText={electionCodeObj.helperText} required label="Election Code" onChange={(e) => setElectionCodeObj({error: false, helperText: '', value: e.target.value}) } />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div>
                            {/* <TextField id="memberCode" error={memberCodeObj.error} helperText={memberCodeObj.helperText} required label="Member Code" onChange={(e)=> setMemberCodeObj({error: false, helperText: '', value: e.target.value})} /> */}
                            <TextField id="section" error={sectionObj.error} helperText={sectionObj.helperText} required label="Section" onChange={(e)=> setSectionObj({error: false, helperText: '', value: e.target.value})} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div>
                            <TextField id="memberName" error={memberNameObj.error} helperText={memberNameObj.helperText} required label="Member Name" onChange={(e) => setMemberNameObj({error: false, helperText: '', value: e.target.value})} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div>
                            <TextField id="assistantPassword" error={passwordObj.error} helperText={passwordObj.helperText} type='password' required label="Password" onChange={(e) => setPasswordObj({error:false, helperText: '', value: e.target.value})} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div>
                            <Button color="success" variant="contained" size="large" onClick={handleLogin}>Login</Button>
                        </div>
                    </Grid>
                       
                    <Grid item xs={12} md={12}>
                        <div>
                            <Link href="/adminLogin">Login here to conduct an election</Link>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <div>
                            <Link href="/Login">Voters click here</Link>
                        </div>
                    </Grid>


                </Grid>
            </Box>
        </React.Fragment>
    )
}
