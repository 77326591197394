import React, {useState, useEffect, useRef} from "react";
import { AppBar, Toolbar, List, ListItem, ListItemAvatar, Avatar, Grid, Box, Typography, ListItemButton } from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import ConfirmationDialog from '../components/ConfirmationDialog';

import Api, {baseURL} from "../Utils/Api";

export default function Voting(props){

    const location = useLocation();
    //const history = useHistory();

    //console.log(props);
    //console.log(location);

    const [showVotes, setShowVotes] = useState(false);
    const [listening, setListening] = useState(false);
    const [nominations, setNominations] = useState([]);
    const [position, setPosition] = useState({name: "", title: "", votesPerPerson: 1});
    const [election, setElection] = useState({jamaatCode: ""}); //list of votes the user is required to cast because of nominating or seconding someone
    const [votes, setVotes] = useState([]);
    const [dialogSettings, setDialogSettings] = useState({title: '', message: '', onOk: function(){}, onCancel: function(){}, ok: 'OK', cancel: 'Cancel' });
    const [open, setOpen] = useState(false);

    const events = useRef();


    function handleEventMessage(e){
        console.log("got server sent event");
        const message = JSON.parse(e.data);
        console.log(message);
        if (message.ev){
            switch (message.ev){
                case "beginVoting":
                    console.log(message.data);
                    setNominations(message.data.nominations);
                    setPosition(message.data.position);
                    setVotes([]);
                    setShowVotes(true);
                    break;
                default:
                    break;
            }
        }
    }

    function castVote(nomination){

    }

    function handleVoteClicked(e, nomination){
        //see if user has already cast all their votes
        console.log("voted for ");
        console.log(nomination);
        if (votes.length >= position.votesPerPerson){
            //show all votes cast message and show list of cast votes
            alert("You have already cast all your votes");
            return;
        }

        setDialogSettings({title: nomination.memberName, 
        message: 'Confirm your vote?',
        ok: 'Yes', cancel: 'No', onCancel: ()=>{setOpen(false)}, onOk: (e)=> {castVote(nomination)} });
        setOpen(true);


    }

    useEffect(()=>{
        console.log("election 1");
        console.log(election);
        if (location.state.election){
            setElection(location.state.election);
        }

        console.log("election 2");
        console.log(election);

        if (!election || !election.id){
            Api.get("/voter/refresh").then((res) => {
                if (res.data.msg && res.data.msg === "ok"){
                    console.log("refresh response");
                    console.log(res.data);
                    setElection(res.data.election);
                    if (res.data.state === "voting"){
                        setPosition(res.data.position);
                        setNominations(res.data.nominations);
                        setVotes(res.data.votes);
                    }
                    setShowVotes(votes.length < position.votesPerPerson);
                }
            });
        }
        
    }, []);

    useEffect(()=> {
        if (!listening){
            events.current = new EventSource(baseURL + "/event/voter", {withCredentials: true});
            events.current.onmessage = handleEventMessage;
            setListening(true);
        }
    }, [listening]);

 
    return <React.Fragment>
        <ConfirmationDialog open={open} title={dialogSettings.title} message={dialogSettings.message}
        ok={dialogSettings.ok} cancel={dialogSettings.cancel} onOk={dialogSettings.onOk}
        onCancel={dialogSettings.onCancel} width={200} />
        <AppBar color="default" sx={{padding:1, alignContent: "center", alignItems: "center"}} position="sticky">
            <Toolbar>
                <h2>Vote for {position.name} </h2>
            </Toolbar>
        </AppBar>
        <Toolbar>
            { ( showVotes ) &&
                <List>
                    {                
                    nominations.map((nomination) => {
                        return (
                            <ListItem key={nomination.memberCode.toString()}>
                                <ListItemButton onClick={ (e) => { handleVoteClicked(e, nomination); } }>
                                    <h3>{nomination.memberName}</h3>
                                </ListItemButton>
                            </ListItem>
                            )
                        })
                    }
                </List>
            }
            {
                (!showVotes) &&
                <Grid container>
                    <Grid item>
                        <Box>
                            <Typography variant="h6">
                                You are taking part in <br/> {election.electionYear} election for <br/> {election.jamaatCode} {election.auxiliary} <br/>
                                please keep this window open <br/> your voting choices will automatically appear here
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            }
        </Toolbar>
    </React.Fragment>
}
