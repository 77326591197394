import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import HowToVoteIcon from '@mui/icons-material/HowToVote';

import _ from 'lodash';
//import { getCheckboxUtilityClass, Stack, Switch, ToggleButton, FormGroup, FormControlLabel } from '@mui/material';
import { Switch, FormGroup, FormControlLabel, Box } from '@mui/material';
import { Container } from '@mui/material';
//import { padding } from '@mui/system';
//import { Paper } from '@mui/material';

import api from '../Utils/Api';

export default function NomineeList({electOffice, nominees, setNominees, showDelete, showVotes, showPhotos, parentNotify}) {

    const [disableDelete, setDisableDelete] = React.useState({});

    console.log(disableDelete);

    const handleToggle = (checked, nomination) => {
        console.log("handleToggle:" + checked + " for " + nomination.nominee.memberCode);
        console.log(disableDelete);
        disableDelete[nomination.nominee.memberCode.toString()] = checked;
        console.log(disableDelete);
        setDisableDelete({...disableDelete});
    }

    const deleteNomination = nomination => () => {
    
        api.delete("/nomination/" + nomination.id).then((res) =>{
            nominees = _.filter(nominees, (e) => {
                return e.nominee !== nomination.nominee && e.nominee.memberCode !== nomination.nominee.memberCode;
            })
            disableDelete[nomination.nominee.memberCode.toString()] = false;
            if (setNominees) setNominees(nominees); else console.log("no setNominees");    
        });
    }

    function voteNomination(nomination){
        if (parentNotify) parentNotify(nomination);
    }

    React.useEffect((nominees) => {
        for (var nom in nominees){
            disableDelete[nom.nominee.memberCode.toString()] = false;
        }
    }, []);

    return (
      <Container maxWidth="xxl">

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {(electOffice) &&
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                Nominees for {electOffice}
                </Typography>
            }
            <Container maxWidth="xxl">
              
              <List sx={{ overflow:'auto'}}>
                {nominees.map((nomination) => {

                    return (
                        <ListItem
                            key={nomination.nominee.memberCode.toString()}
                            
                            secondaryAction={ showDelete &&
                                (<FormGroup>
                                    
                                    <FormControlLabel label="Delete" control={<Switch onChange={(ev) => { handleToggle(ev.target.checked, nomination); } } color="secondary"  />} />                                    
                                    <IconButton disabled={(disableDelete[nomination.nominee.memberCode.toString()] ^ true) === 1 } edge="end" aria-label="delete" color="secondary" onClick={deleteNomination(nomination)} sx={{width:42}}>
                                        <DeleteIcon />
                                    </IconButton>
                                    
                                </FormGroup>)

                                || showVotes &&
                                (
                                    <FormGroup>
                                    
                                    <IconButton edge="end" aria-label="delete" color="secondary" onClick={(e)=> {voteNomination(nomination)}} sx={{width:42}}>
                                        <HowToVoteIcon />
                                    </IconButton>
                                    
                                </FormGroup>
                                )

                                }
                        >
                            { showPhotos &&
                            <ListItemAvatar>
                                <Avatar sx={{width:130, height:130}} src={nomination.nominee.photo} variant="rounded" />
                            </ListItemAvatar>
                            }
                            <ListItemText secondaryTypographyProps={{variant: "h7"}} primaryTypographyProps={{variant: "h4"}} sx={{padding:1}}
                            primary={nomination.nominee.name}
                            secondary={"By: " + nomination.nominator.name + " / " + nomination.seconder.name}
                            />
                        {showVotes &&
                        <Box>
                                    <h1>{nomination.voteCount}</h1>
                        </Box>}
                        </ListItem>)})
                }

              </List>

            </Container>
          </Grid>
        </Grid>
      </Container>
    );   
}
