import React, {useState, useEffect} from 'react';
import ChairOutlinedIcon from '@mui/icons-material/ChairOutlined';
import ChairTwoToneIcon from '@mui/icons-material/ChairTwoTone';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';

//import { getCheckboxUtilityClass, Stack, Switch, ToggleButton, FormGroup, FormControlLabel } from '@mui/material';
import { Checkbox } from '@mui/material';
import { blue, green, grey, red, orange, brown } from '@mui/material/colors';

import ConfirmationDialog from './ConfirmationDialog';

const label = { inputProps: { 'aria-label': 'Seat' } };

const normalCheckbox= {
    color: red[600],
    '&.Mui-checked': {
      color: green[600],
    }};

const selectedCheckbox= {
    color: brown[500],
    '&.Mui-checked': {
        color: green[300],
    }};
    

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
          right: 9,
          top: 12,
          border: `2px solid ${theme.palette.background.paper}`,
          padding: '0 4px',
        },
      }));
  

export default function VoteChair({voteCount, maxVotes, alreadyVoted, defaultChecked, section, row, column, parentNotify}) {
    const [badge, setBadge] = useState({color:"secondary", content: voteCount});
    const [checked, setChecked] = useState(defaultChecked);
    const [open, setOpen] = useState(false);


    if (!maxVotes) maxVotes = 1;
    if (!voteCount) voteCount = 0;

    const visible = (maxVotes > 1 && voteCount > 0 || voteCount > 1);
    if (maxVotes === 1){
        alreadyVoted = (alreadyVoted || (voteCount > 0));
    } else {
        alreadyVoted = (voteCount >= maxVotes);
    }

    const [renderBrown, setRenderBrown] = useState(alreadyVoted);

    const [disabled, setDisabled] = useState( alreadyVoted && (maxVotes > 1));

    function handleChange(e){
        if (parentNotify){
            if (!parentNotify(section, row, column, e.target.checked))
                return false;
        }

        if (e.target.checked){
            setBadge({color: "success", content: badge.content + 1});
            setChecked(true);
        } else {
            setBadge({color: "secondary", content: badge.content - 1});
            setChecked(false);
        }
    }

    function confirmChange(e){

    }

    return (
        <React.Fragment>
        <ConfirmationDialog open={open} title={"Swap vote?"}
        message={`This person already voted previously, are you sure you want to continue?`}
        ok="Yes" cancel="Cancel"
        onOk={(e)=> { handleChange(e); }}
        onCancel={() => {setOpen(false);}} />


            {renderBrown &&
            <StyledBadge badgeContent={badge.content} color={badge.color} size="small" invisible={!visible}>
                <Checkbox {...label} icon={<ChairTwoToneIcon/>} checkedIcon={<ChairTwoToneIcon/>} sx={selectedCheckbox} onChange={handleChange} checked={checked} disabled={disabled} />
            </StyledBadge>
            }
            
            {!renderBrown &&
            <StyledBadge badgeContent={badge.content} color={badge.color} size="small" invisible={!visible}>
                <Checkbox {...label} icon={<ChairTwoToneIcon/>} checkedIcon={<ChairTwoToneIcon/>} sx={normalCheckbox} onChange={handleChange} checked={checked} disabled={disabled} />
            </StyledBadge>
            }

        </React.Fragment>
    );   
}
