//import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
//import Home from './containers/Home';
import Nomination from './containers/Nomination.js';
import { Paper, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { Container } from '@mui/material';
import Voting from './containers/Voting';
import Quorum from './containers/Quorum';
import Login from './containers/Login';
import AssistantLogin from './containers/AssistantLogin';
import AdminLogin from './containers/AdminLogin';
import Elections from './containers/Elections';
import ElectionPositions from './containers/ElectionPositions';
import VotingResults from './containers/VotingResults';
import VotingChairs from './containers/VotingChairs';
import Assistant from './containers/Assistant';

const darkTheme = createTheme({
  palette:{
      mode: 'dark',
  },
});

//TODO: secure client routes based on role
//TODO: get public key from server and validate client side credentials

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={darkTheme}>

        <div className="App">
          <div className="content">
            
              <Switch>
                <Route path="/nomination">
                    <Nomination electOffice="General Secretary" />
                </Route>

                <Route path="/voting">
                    <Voting />
                </Route>

                <Route path="/votingResults">
                    <VotingResults />
                </Route>

                <Route path="/quorum">
                    <Quorum />
                </Route>

                <Route exact path="/">
                  <Login />
                </Route>

                <Route exact path="/login">
                  <Login />
                </Route>

                <Route exact path="/assistantLogin">
                  <AssistantLogin />
                </Route>

                <Route exact path="/adminLogin">
                  <AdminLogin />
                </Route>

                <Route exact path="/elections">
                  <Elections />
                </Route>

                <Route exact path="/electionPositions">
                  <ElectionPositions />
                </Route>
                
                <Route exact path="/votingChairs">
                  <VotingChairs />
                </Route>

                <Route exact path="/assistant">
                  <Assistant />
                </Route>

            </Switch>
          </div>
        </div> 
      </ThemeProvider>
    </BrowserRouter>
    
  );
}

export default App;
