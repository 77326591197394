import React from 'react';
import { Typography } from '@mui/material';
import {Modal, Box, Button, Stack} from '@mui/material';

const dialogStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function ConfirmationDialog(props){
    const {title, message, onOk, onCancel, open} = props;
    const startTxt = props.ok || "OK";
    const cancelTxt = props.cancel || "Cancel";
    dialogStyle.width = props.width || dialogStyle.width;
    return <React.Fragment>
                <Modal open={open} onClose={onCancel}>
                    <Box sx={dialogStyle}>
                        <Typography variant="h6" component="h2">{title}</Typography>
                        
                        <Typography sx={{mt: 4}}>
                            {message}
                        </Typography>
                        <Stack direction="row" spacing={2} sx={{mt: 4, alignContents: 'right'}}>
                            <Button color="success" variant="contained" size="large" onClick={onOk}>{startTxt}</Button>
                            <Button color="secondary" variant="outlined" size="large" onClick={onCancel}>{cancelTxt}</Button>
                        </Stack>
                    </Box>
                </Modal>
            </React.Fragment>

}