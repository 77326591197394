import axios from 'axios';

//TODO: load baseURL from .env file
export const baseURL = "https://vote.amchq.us/api";

const api = axios.create({
    baseURL: baseURL,
    timeout: 10000,
    withCredentials: true
})

api.interceptors.response.use((res) => {return res;}, (err) =>{
    if (err && err.response){
        console.log("Failed with error " + err.status + " msg:" + err.response.data);
        //TODO: surround with env
        alert(err + " " + err.response.data);
    }
    throw err;
})

export default api;
