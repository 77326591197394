import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';

import {Button, CssBaseline, TextField, Box, Grid, Typography, Link} from '@mui/material';
import api from '../Utils/Api';

//TODO: route to appropriate home page if already logged in?

export default function AdminLogin(props) {

    const [emailObj, setEmailObj] = useState({error: false, helperText: '', value: ''});
    const history = useHistory();

    function handleLogin(e){
        if (emailObj && emailObj.value){

            api.post("/login/admin", {email: emailObj.value})
            .then(function (res){
                const {name, role, elections} = res.data;
                //TODO: save user info to localStorage so app knows user is already logged in
                history.push({
                    pathname: '/elections',
                    state: {name: name, role: role, elections: elections}
                });
            })
            .catch(function (err){
                //TODO: clear any saved localStorage to logout the user
                if (err.response){
                    setEmailObj({...emailObj, helperText: err.response.data, error: true});
                } else {
                    setEmailObj({...emailObj, helperText: "Login failed with error " + err.status, error: true});
                }
            });

        } else {
            setEmailObj({error: true, helperText: 'Required', value: ''})
        }
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <Box 
                        height="100vh"
                        component="form"
                        sx={{'& .MuiTextField-root': { width: '30ch'}}}
                        noValidate
                    >
                <Grid style={{height:"350px", width: "100%"}} container spacing={3} alignItems="center" justifyContent="space-evenly">

                    <Grid item xs={12} md={12}>
                        <Typography variant="h6">You must use your @ahmadiyya.us email account to login via Google</Typography>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <div>
                            <TextField id="email" error={emailObj.error} helperText={emailObj.helperText} required label="Email" onChange={(e) => setEmailObj({error: false, helperText: '', value: e.target.value}) } />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <div>
                            <Button color="success" variant="contained" size="large" onClick={handleLogin}>Login</Button>
                        </div>
                    </Grid>
                       
                    <Grid item xs={12} md={12}>
                        <div>
                            <Link href="/assistantLogin">Assistants click here</Link>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <div>
                            <Link href="/Login">Voters click here</Link>
                        </div>
                    </Grid>


                </Grid>
            </Box>
        </React.Fragment>
    )
}
