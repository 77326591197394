import React, {useState, useEffect} from 'react';
import members from '../data/mockdata.json';
//import { Box } from '@mui/system';
//import MemberSearch from '../components/MemberSearch';
import VirtualMemberSearch from '../components/VirtualMemberSearch';
import { Button, Card, CardMedia, CssBaseline, Toolbar, Typography, Alert, AlertTitle, Collapse } from '@mui/material';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Popper } from '@mui/material';
import { AppBar } from '@mui/material';
// import '@fontsource/roboto/400.css';
import NomineeList from '../components/NomineeList';
import Fab from '@mui/material/Fab';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { useHistory, useLocation } from 'react-router-dom';
import api from '../Utils/Api';

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
//     textAlign: 'center',
//     alignItems: 'middle',
//   };

const getErr = (txt="")=>{ return {err: (txt !== ""), txt: txt}};

export default function VotingResults(props){

    const [position, setPosition] = useState({});
    const [election, setElection] = useState({});

    const listRef = React.useRef();

    const [nominees, setNominees] = useState([]);

    const [errAlertOpen, setErrAlertOpen] = useState(false);


    const [open, setOpen] = React.useState(false);

    const history = useHistory();
    const location = useLocation();
    
    const loadLocationState = (location) => {
        if (location && location.state){
            const {election, position, nominees} = location.state;
            setPosition(position);
            setElection(election);
            setNominees(nominees);
            console.log("loaded state");
            console.log(position);
            console.log(election);
        }
    };

    function nominationClicked(nomination){
        console.log("nominationClicked");
        console.log(nomination);
        console.log(election);
        console.log(position);

        api.post("/nomination/", {}).then((res)=>{

        }).catch((err)=>{

        });

        history.push({
            pathname: '/votingChairs',
            state: {nomination: nomination, election: election, position: position, nominees: nominees}
        });
    }
    
    useEffect(()=> {loadLocationState(location);}, [] );

    return <React.Fragment>
        <CssBaseline />


        
            <AppBar color="default" sx={{padding:2}} position="sticky" >
                <Toolbar>
                    <Grid container spacing={3} alignItems="center" justifyContent="space-evenly">
                        <Grid item xs={12} md={12}>
                            <Card>
                                <h2>Nominees for {position.name}/{position.title}</h2>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            
                        </Grid>
                        <Grid item xs={12} md={3}>
                            
                        </Grid>
                        <Grid item xs={12} md={3}>

                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Stack direction="row" spacing={2}>
                                <Button color="success" variant="contained" size="large" startIcon={<AddReactionIcon />}>Finish</Button>
                                <Button color="secondary" variant="outlined" size="large" endIcon={<BackspaceIcon />}>Cancel</Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Toolbar>
                <Grid container spacing={2} paddingTop={1}>
                    <Grid item xs={0} md={5}></Grid>
                    <Grid item xs={12} md={3}>
                        <Collapse in={errAlertOpen}>
                        <Alert severity="error" variant="filled" >
                            <AlertTitle>Error</AlertTitle>
                            There must be at least {position.minimumNominations} nominees to begin voting
                        </Alert>
                        </Collapse>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <NomineeList nominees={nominees} setNominees={setNominees} ref={listRef} showDelete={false} showVotes={true} showPhotos={true} parentNotify={nominationClicked} />
                    </Grid>
                </Grid>
            </Toolbar>
    </React.Fragment>
};