import React, {useState, useEffect} from 'react';
import members from '../data/mockdata.json';
//import { Box } from '@mui/system';
//import MemberSearch from '../components/MemberSearch';
import VirtualMemberSearch from '../components/VirtualMemberSearch';
import { Button, Card, CardMedia, CssBaseline, Toolbar, Typography, Alert, AlertTitle, Collapse } from '@mui/material';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Popper } from '@mui/material';
import { AppBar } from '@mui/material';
// import '@fontsource/roboto/400.css';
import NomineeList from '../components/NomineeList';
import Fab from '@mui/material/Fab';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { useHistory, useLocation } from 'react-router-dom';
import api from '../Utils/Api';

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
//     textAlign: 'center',
//     alignItems: 'middle',
//   };

const getErr = (txt="")=>{ return {err: (txt !== ""), txt: txt}};

export default function Nomination(props){

    const [currNominee, setCurrNominee] = useState(null);
    const [currNominator, setCurrNominator] = useState(null);
    const [currSeconder, setCurrSeconder] = useState(null);
    const [errNominee, setErrNominee] = useState(getErr());
    const [errNominator, setErrNominator] = useState(getErr());
    const [errSeconder, setErrSeconder] = useState(getErr());
    const [position, setPosition] = useState({});
    const [election, setElection] = useState({});

    const listRef = React.useRef();

    //const localStorageNominees = "nomination.nominees";

    //const localNomineesStr = localStorage.getItem(localStorageNominees);
    //var localNominees = [];
    //if (localNomineesStr)
    //    localNominees=JSON.parse(localNomineesStr);

    const [nominees, setNominees] = useState([]);

    const [errAlertOpen, setErrAlertOpen] = useState(false);


    const [open, setOpen] = React.useState(false);

    const history = useHistory();
    const location = useLocation();
    
    const loadLocationState = (location) => {
        if (!location.state) return;
        const {election, position} = location.state;
        setPosition(position);
        setElection(election);
    };
    
    useEffect(()=> {
        loadLocationState(location);
        let currPos = position;
        if (!currPos || !currPos._id){
            if (location && location.state) 
                currPos = location.state.position;
            else
                return;
        }
        
        api.get("/nomination/list/" + currPos._id).then(
            (res) => {
                setNominees(res.data.nominations);
                clearSelection();
            }
         )
    }, [] );

    const handleOpen = (ev) => {setOpen(true);}
    const handleClose = (ev) => {setOpen(false);}

    const voters = members.filter(m => m.canVote === true);
    const electables = members.filter(m => m.electable === true);


    function nomineeSelected(ev, nominee){
        setCurrNominee(nominee);
        if (nominee){
            clearErrNominee();
            handleOpen(ev);
        } else {
            setOpen(false);
        }
    }

    function nominatorSelected(ev, nominator){
        setCurrNominator(nominator);
        if (nominator){
            clearErrNominator();
        }
    }

    const seconderSelected = (ev, seconder) => {
        setCurrSeconder(seconder);
        if (seconder){
            clearErrSeconder();
        }
    }

    function clearErrNominee(){
        setErrNominee(getErr());
    }

    function clearErrNominator(){
        setErrNominator(getErr());
    }

    function clearErrSeconder(){
        setErrSeconder(getErr());
    }

    function clearSelectionError(){
        clearErrNominee();
        clearErrNominator();
        clearErrSeconder();
    }

    function clearSelection(){
        setOpen(false);
        setCurrNominee(null);
        setCurrNominator(null);
        setCurrSeconder(null);
        clearSelectionError();
        setErrAlertOpen(false);
    }

    function beginVoting(){
        if (nominees.length < position.minimumNominations){
            setErrAlertOpen(true);
            return;
        }
        api.get("/election/startVoting/" + position._id).then((res)=>{
            history.push({
                pathname: '/votingResults',
                state: {nominees: nominees, position: position, election: election}
            })    
        });

    }

    function getNoOfVoterErrText(numOfVotes){
        if (numOfVotes === 1) return " 1 person";
        return " " + numOfVotes.toString() + " people";
    }

    function getNominatorSummary(){
        const nominators = {};
        nominees.forEach((nomination)=> {
            if (nomination.nominator.memberCode in nominators)
            {
                nominators[nomination.nominator.memberCode].nomCount += 1;
            }else{
                nominators[nomination.nominator.memberCode] = {nomCount: 1};
            }
            if (nomination.seconder.memberCode in nominators){
                nominators[nomination.seconder.memberCode].nomCount += 1;
            } else {
                nominators[nomination.seconder.memberCode] = {nomCount: 1};
            }
        })
        return nominators;
    }

    function validateSelection(){
        let err=false;
        setErrAlertOpen(false);
        if (!currNominee){
            setErrNominee(getErr("Nominee is required"));
            err=true;
        }
        if (!currNominator){
            setErrNominator(getErr("Nominator is required"));
            err=true;
        }
        if (!currSeconder){
            setErrSeconder(getErr("Seconder is required"));
            err=true;
        }

        if ((currNominee && currNominator) && ((currNominee === currNominator)|| (currNominee.memberCode === currNominator.memberCode))){
            setErrNominator(getErr("Nominator and nominee must be different"));
            err=true;
        }

        if ((currNominee && currSeconder) && ((currNominee === currSeconder)|| (currNominee.memberCode === currSeconder.memberCode))){
            setErrSeconder(getErr("Seconder and nominee must be different"));
            err=true;
        }
        
        if ((currNominator && currSeconder) && ((currNominator === currSeconder)|| (currNominator.memberCode === currSeconder.memberCode))){
            setErrSeconder(getErr("Seconder and nominator must be different"));
            err=true;
        }

        const nominatorSummary = getNominatorSummary();

        let nomErr=false;
        for (const idx in nominees){
            const nomination = nominees[idx];
            console.log("evaluating:" + nomination);
            if (nomination.nominee && (nomination.nominee === currNominee || nomination.nominee.memberCode === currNominee.memberCode)){
                err=true;
                nomErr=true;
                setErrNominee(getErr("Already nominated previously by: " + nomination.nominator.name));
                console.log("already nominated");
            }
            if (position.votesPerPerson <= 1)
            {
                if (nomination.nominator && (nomination.nominator === currNominator || nomination.nominator.memberCode === currNominator.memberCode)){
                    err=true;
                    nomErr=true;
                    setErrNominator(getErr("Each person may nominate only " + getNoOfVoterErrText(position.votesPerPerson) + ", " + nomination.nominator.name + " has already nominated " + nomination.nominee.name));
                    console.log("dup nominator");
                }
                if (nomination.nominator && (nomination.nominator === currSeconder || nomination.nominator.memberCode === currSeconder.memberCode)){
                    err=true;
                    nomErr=true;
                    setErrNominator(getErr("Each person may second only" + getNoOfVoterErrText(position.votesPerPerson) + ", " + nomination.nominator.name + " has already seconded " + nomination.nominee.name));
                    console.log("nominator already seconded");
                }
                if (nomination.seconder && (nomination.seconder === currSeconder || nomination.seconder.memberCode === currSeconder.memberCode)){
                    err=true;
                    nomErr=true;
                    setErrSeconder(getErr("Each person may second only" + getNoOfVoterErrText(position.votesPerPerson) + ", " + nomination.seconder.name + " has already seconded " + nomination.nominee.name));
                    console.log("dup seconder");
                }
                if (nomination.seconder && (nomination.seconder === currNominator || nomination.seconder.memberCode === currNominator.memberCode)){
                    err=true;
                    nomErr=true;
                    setErrSeconder(getErr("Each person may nominate or second only one person, " + nomination.seconder.name + " has already nominated " + nomination.nominee.name));
                    console.log("seconder already nominated");
                } 
            } else {
                if (nomination.nominator && nomination.nominator.memberCode in nominatorSummary && nominatorSummary[nomination.nominator.memberCode].nomCount >= position.votesPerPerson){
                    const nomCount = nominatorSummary[nomination.nominator.memberCode].nomCount;
                    err=true;
                    nomErr=true;
                    setErrNominator(getErr("Each person may nominate or second only " + getNoOfVoterErrText(position.votesPerPerson) + ", " + nomination.nominator.name + " has already nominated or seconded " + getNoOfVoterErrText(nomCount)));
                    console.log("dup nominator nn");    
                }
                if (nomination.seconder && nomination.seconder.memberCode in nominatorSummary && nominatorSummary[nomination.seconder.memberCode].nomCount >= position.votesPerPerson){
                    const nomCount = nominatorSummary[nomination.seconder.memberCode];
                    err = true;
                    nomErr = true;
                    setErrNominator(getErr("Each person may nominate or second only " + getNoOfVoterErrText(position.votesPerPerson) + ", " + nomination.nominator.name + " has already nominated or seconded " + getNoOfVoterErrText(nomCount)));
                }
            }
            if (nomErr) break;
        }

        return (!err);
    }

    function addNomination(){
        if (!validateSelection()) return;

        console.log("Adding current nominee:" + currNominee.name + " who was nominated by: " + currNominator.name + " seconded by:" + currSeconder.name);
        const currNom = {nominee: currNominee, nominator: currNominator, seconder: currSeconder};
        const nomReq = {electionId: election._id, electionPositionId: position._id, nomination: currNom };
        console.log(nomReq);
        api.post("/nomination/add", nomReq).then( (res) => {
            //todo: make this more robust so in case of error from server there is a better way to handle this
             currNom.id = res.data.nomination._id;
             nominees.unshift(currNom);
             //localStorage.setItem(localStorageNominees, JSON.stringify(nominees));
             setNominees(nominees);
             clearSelection();         
        });
    }

    return <React.Fragment>
        <CssBaseline />


        
            <AppBar color="default" sx={{padding:2}} position="sticky" >
                <Toolbar>
                    <Grid container spacing={3} alignItems="center" justifyContent="space-evenly">
                        <Grid item xs={12} md={12}>
                            <Card>
                                <h2>Nominations for {position.name}/{position.title} ( nominations: {nominees.length} minimum: {position.minimumNominations} )</h2>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <VirtualMemberSearch members={electables} label="Nominee" error={errNominee.err} helperText={errNominee.txt} onSelect={nomineeSelected} value={currNominee} key='ac-nominee'></VirtualMemberSearch>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <VirtualMemberSearch members={voters} label="Nominated by" error={errNominator.err} helperText={errNominator.txt} onSelect={nominatorSelected} value={currNominator} key='ac-nominator'></VirtualMemberSearch>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <VirtualMemberSearch members={voters} label="Seconded by" error={errSeconder.err} helperText={errSeconder.txt} onSelect={seconderSelected} value={currSeconder} key='ac-seconder'></VirtualMemberSearch>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Stack direction="row" spacing={2}>
                                <Button color="success" variant="contained" size="large" onClick={addNomination} startIcon={<AddReactionIcon />}> Nominate</Button>
                                <Button color="secondary" variant="outlined" size="large" onClick={clearSelection} endIcon={<BackspaceIcon />}>Clear</Button>
                                <Fab color="primary" variant="extended" size="large" onClick={beginVoting}><HowToVoteIcon /> Begin Voting</Fab>
                            </Stack>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Toolbar>
                <Grid container spacing={2} paddingTop={1}>
                    <Grid item xs={0} md={5}></Grid>
                    <Grid item xs={12} md={3}>
                        <Collapse in={errAlertOpen}>
                        <Alert severity="error" variant="filled" >
                            <AlertTitle>Error</AlertTitle>
                            There must be at least {position.minimumNominations} nominees to begin voting
                        </Alert>
                        </Collapse>
                    </Grid>
                    <Grid item xs={12} md={12}>
                            <Popper disableEnforceFocus open={open} onClose={handleClose} style={{top: '40vh', left: '40vw'}}>
                                    <Card variant="outlined">
                                        <Stack direction="column" alignItems="center">
                                            <Typography variant="h4">
                                                Nominee
                                            </Typography>
                                            <CardMedia component="img" image={currNominee && currNominee.photo} height="400" />
                                            <Typography variant="h3">
                                                {currNominee && currNominee.name}
                                            </Typography>
                                        </Stack>
                                    </Card>
                            </Popper>
                        <NomineeList nominees={nominees} setNominees={setNominees} ref={listRef} showDelete={true} showPhotos={true} />
                    </Grid>
                </Grid>
            </Toolbar>
    </React.Fragment>
};