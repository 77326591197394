import React, {useState, useEffect, useRef} from "react";
import { Stack, Container, Typography, CardMedia } from "@mui/material";
import Api, {baseURL} from "../Utils/Api";

import VoteChairSection from "../components/VoteChairSection";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "@mui/material";

export default function VotingChairs(props){
    const sections = [];
    const sectionNames = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let nameIdx = 0;
    const [voteCount, setVoteCount] = useState(0);
    const history = useHistory();
    const location = useLocation();

    const {position, nomination, election, nominees} = location.state;

    console.log("position");
    console.log(position);
    console.log("nomination");
    console.log(nomination);
    console.log("election");
    console.log(election);

    let colCount = election.columnsPerSection;
    let chairCount = election.totalChairs - election.headTableChairs;
    let headTableVoters = election.headTableChairs;
    let chairsPerSection = election.chairsPerSection;

    //const voteData = {"A12": {count: 2, names: []}, "head11": {count: 2, names: []}, "C32": {count: 1, names: []} };
    const [voteData, setVoteData] = useState({});

    function finishVoting(){
        history.push({
            pathname: "/votingResults",
            state: {position: position, election: election, nominees: nominees}
        })
    }

    function chairClicked(section, row, column, checked){
        console.log("chairClicked " + section + " r:" + row + " c:" + column + " chk:" + checked);
        if (checked){
            setVoteCount(voteCount + 1);
        } else {
            setVoteCount(voteCount - 1);
        }

        return true;
    }

    for (let s=0; s<chairCount; s=s+chairsPerSection){
        if ( (chairCount - s ) < chairsPerSection ) chairsPerSection = chairCount - s;
        const name = sectionNames.slice(nameIdx, nameIdx+1);
        sections.push( <VoteChairSection chairCount={chairsPerSection} colCount={colCount} name={name} key={name} parentNotify={chairClicked} voteData={voteData} /> );
        nameIdx=nameIdx+1;
    }
 
    return <React.Fragment>
        <Container maxWidth="sm">
            <Typography variant="h6" color="primary">{position.name} / {position.title}</Typography>
            <Typography variant="h4" color="secondary"> {nomination.nominee.name} ({voteCount}) </Typography>
            <CardMedia component="img" image={nomination.nominee.photo} height="400" width="300" />
        </Container>
            { (headTableVoters > 0) &&
            <Container maxWidth="xs" justifyContent="center" >
                <VoteChairSection chairCount={headTableVoters} colCount={1} name={"head"} parentNotify={chairClicked} stackDirection="row" voteData={voteData} memberCode={nomination.nominee.memberCode} />
            </Container>
            }
            <br/>
            <Stack direction="row" spacing={3}>
                {sections}
            </Stack>
            <br/>
            <Button color="success" variant="contained" size="large" onClick={finishVoting} > Finish Voting</Button>
    </React.Fragment>
}
