import React, {useState, useEffect} from 'react';
import VoteChair from './VoteChair';
import { Stack } from '@mui/material';

export default function VoteChairRow({section, row, chairCount, maxVotes, voteData, memberCode, parentNotify}) {
    const chairs = [];

    for(let c = 1; c < chairCount+1; c++){
        const key = section + row + c;
        let voteCount = 0;
        let defaultChecked;

        if (voteData){
            if (key in voteData){
                voteCount = voteData[key].count;
                for(var i = 0; i < voteData[key].names.length; i++){
                    var curr = voteData[key].names[i];
                    if (curr.memberCode === memberCode){
                        defaultChecked = true;
                        voteCount = voteCount - 1;
                    }
                }
            }
        }
        chairs.push(<VoteChair section={section} row={row} column={c} parentNotify={parentNotify} key={key} maxVotes={maxVotes} voteCount={voteCount} defaultChecked={defaultChecked} />);
    }

    return (
        <React.Fragment>
            <Stack direction={"row"} alignItems="center" textAlign={'center'} justifyContent="center">
                {chairs}
            </Stack>
        </React.Fragment>
    );   
}
