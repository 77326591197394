import * as React from 'react';
//import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import { FixedSizeList } from 'react-window';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { Avatar } from '@mui/material';
import {matchSorter} from 'match-sorter';

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const option = dataSet[1];

  return (
      <Box component="li" style={style} {...dataSet[0]}>
          <Avatar
              src={option.photo}
              alt={option.name}
              sx={{ width:36, height: 36}}
              //component="img"
              />
          <Typography>
              {option.name} ({option.memberCode})
          </Typography>
      </Box>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  console.log(props);
  const { children, ...other } = props;
  const itemData = [];

  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const itemCount = itemData.length;
  
  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          itemData={itemData}
          height={400}
          width="100%"
          outerElementType={OuterElementType}
          innerElementType="ul"
          overscanCount={5}
          itemCount={itemCount}
          itemSize={36}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

//AAM: I don't know what this does other than to cause react to throw an error in console about passing an invalid children type to forwardRef
// ListboxComponent.propTypes = {
//   children: PropTypes.node,
// }

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const filterOptions = (options, {inputValue}) => matchSorter(options, inputValue, {keys: ['name', 'memberCode']});

export default function VirtualMemberSearch({members, label, onSelect, value, error, helperText}) {
  return (
    <Autocomplete
    //   id="virtualize-demo"
    //   sx={{ width: 300 }}
      disableListWrap
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      value={value}
      onChange={(event, newValue) => { if (onSelect) onSelect(event, newValue);}}
      options={members}
      renderInput={(params) => <TextField {...params} label={label} error={error} helperText={helperText} inputProps={{...params.inputProps, autoComplete: 'new-password'}} />}
      getOptionLabel={(option) => {if (option && option.name) return option.name; return "";}}
      autoComplete
      autoSelect
      clearOnEscape
      handleHomeEndKeys
      autoHighlight
      filterOptions={filterOptions}
      renderOption={(props, option) => [props, option]}
    />
  );
}