import React, {useState, useEffect} from 'react';
import VoteChairRow from './VoteChairRow';
import { Stack, Container, Typography, Paper } from '@mui/material';
import ChairOutlined from '@mui/icons-material/ChairOutlined';
import { green } from '@mui/material/colors';

export default function VoteChairSection({chairCount, colCount, name, maxVotes, voteData, parentNotify, memberCode, stackDirection="column"}) {
    const chairs = [];
    let totalCount = 0;
    for (let key in voteData){
        if (voteData.hasOwnProperty(key)){
            if (key[0] === name){
                for (var i = 0; i< voteData[key].names.length; i++){
                    const currName = voteData[key].names[i];
                    if (currName.memberCode === memberCode){
                        totalCount = totalCount + voteData[key].count;
                    }
                }
            }
        }
    }
    const [count, setCount] = useState(totalCount);
    const [color, setColor] = useState("success");

    function chairClicked(section, row, column, checked){
        if (!parentNotify(section, row, column, checked))
            return false;
            
        if (checked){
            setCount(count + 1);
        } else {
            setCount(count - 1);
        }
        
    }

    for(let r = 0; r < chairCount; r=r+colCount){
        if ((chairCount - r) < colCount) colCount = chairCount - r;
        const key= name + (chairs.length + 1).toString();
        chairs.push(<VoteChairRow chairCount={colCount} section={name} row={chairs.length + 1} parentNotify={chairClicked} key={key} maxVotes={maxVotes} voteData={voteData} memberCode={memberCode} />);
    }

    //const remChairs = chairCount % colCount;
    //chairs.push(<VoteChairRow chairCount={remChairs} /> );

    return (
        <React.Fragment>
            <Container>
                <Paper mt={1}>
                    <span><Typography color={color} variant="h5">{name}</Typography> <Typography color="secondary" variant="h7">{count}</Typography> </span>
                    <Stack direction={stackDirection} justifyContent="center">
                        {chairs}
                    </Stack>
                </Paper>
            </Container>
        </React.Fragment>
    );   
}
