import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';

import {Button, CssBaseline, TextField, Box, Grid, Typography, Link} from '@mui/material';

import api from '../Utils/Api';

//TODO: route to appropriate home page if already logged in?

export default function Login(props) {
    const history = useHistory();

    const [electionCode, setElectionCode] = useState("");
    const [electionCodeErr, setElectionCodeErr] = useState(false);
    const [electionCodeHelperText, setElectionCodeHelperText] = useState("");
    const [memberCode, setMemberCode] = useState("");
    const [memberCodeErr, setMemberCodeErr] = useState(false);
    const [memberCodeHelperText, setMemberCodeHelperText] = useState("");
    const[memberName, setMemberName] = useState("");
    const [memberNameErr, setMemberNameErr] = useState(false);
    const [memberNameHelperText, setMemberNameHelperText] = useState("");

    function validate() {
        console.log("electionCode:" + electionCode);
        console.log("memberCode:" + memberCode);
        console.log("memberName:" + memberName);

        setElectionCodeErr(false);
        setMemberCodeErr(false);
        setMemberNameErr(false);
        setElectionCodeHelperText("");
        setMemberCodeHelperText("");
        setMemberNameHelperText("");
        if (!electionCode){
            setElectionCodeErr(true);
            setElectionCodeHelperText("Required");
        }
    
        if (!memberCode){
            setMemberCodeErr(true);
            setMemberCodeHelperText("Required");
        }
    
        if (!memberName){
            setMemberNameErr(true);
            setMemberNameHelperText("Required");
        }

        if (electionCode){
            console.log(electionCode);
            if (electionCode.length !== 5){
                setElectionCodeErr(true);
                setElectionCodeHelperText("Must be 5 characters")
            }
        }

        if (memberCode){
            if (memberCode.length > 5){
                setMemberCodeErr(true);
                setMemberCodeHelperText("Must be 5 numbers");
            }
            const memberCodeNumber = parseInt(memberCode);
            if (memberCodeNumber < 20000 || memberCodeNumber > 70000){
                setMemberCodeErr(true);
                setMemberCodeHelperText("Invalid member code");
            }
        }

        return true;
    }

    function handleLogin(ev){
        if (validate()){
            api.post("/login/voter", {memberCode: memberCode, memberName: memberName, electionCode: electionCode})
            .then(function (res){
                //TODO: save user info to localStorage so app knows user is already logged in
                history.push({
                    pathname: '/voting',
                    state: {memberCode: memberCode, memberName: memberName, electionCode: electionCode}
                });
            })
            .catch(function (err){
                //TODO: clear any saved localStorage to logout the user
                if (err.response){
                    //setEmailObj({...emailObj, helperText: err.response.data, error: true});
                } else {
                    //setEmailObj({...emailObj, helperText: "Login failed with error " + err.status, error: true});
                }
            });

        }
    }
    

    return (
        <React.Fragment>
            <CssBaseline />
            <Box 
                        height="100vh"
                        component="form"
                        sx={{'& .MuiTextField-root': { width: '30ch'}}}
                        noValidate
                    >
                <Grid style={{height:"350px", width: "100%"}} container spacing={3} alignItems="center" justifyContent="space-evenly">

                    <Grid item xs={12} md={12}>
                        <Typography variant="h6">Please login to take part in the election</Typography>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <div>
                            <TextField id="electionCode" error={electionCodeErr} helperText={electionCodeHelperText} required label="Election Code" onChange={(e) => setElectionCode(e.target.value) } />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div>
                            <TextField id="memberCode" error={memberCodeErr} helperText={memberCodeHelperText} required label="Member Code" onChange={(e)=> setMemberCode(e.target.value)} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div>
                            <TextField id="memberName" error={memberNameErr} helperText={memberNameHelperText} required label="Member Name" onChange={(e) => setMemberName(e.target.value)} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div>
                            <Button color="success" variant="contained" size="large" onClick={handleLogin}>Login</Button>
                        </div>
                    </Grid>
                       
                    <Grid item xs={12} md={12}>
                        <div>
                            <Link href="/adminLogin">Login here to conduct an election</Link>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <div>
                            <Link href="/assistantLogin">Assistants click here</Link>
                        </div>
                    </Grid>


                </Grid>
            </Box>
        </React.Fragment>
    )
}
