import { AppBar, CssBaseline, Toolbar, Grid, Card, List, ListItem, ListItemText, Box, Button, Stack, Fab } from "@mui/material";
import React, {useState, useEffect, useRef} from "react";
import { useHistory, useLocation } from "react-router-dom";
import Api, {baseURL} from "../Utils/Api";
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import BackspaceIcon from '@mui/icons-material/Backspace';
import ConfirmationDialog from '../components/ConfirmationDialog';

export default function Quorum(props){
    const history = useHistory();
    const location = useLocation();


    const [checkedIn, setCheckedIn] = useState(0);
    const [listening, setListening] = useState(false);
    const [voters, setVoters] = useState([]);
    const [open, setOpen] = useState(false);
    const [dialogSettings, setDialogSettings] = useState({title: '', message: '', onOk: function(){}, onCancel: function(){}, ok: 'OK', cancel: 'Cancel' });

    const {election, name, role} = location.state;

    const eventSource = useRef();
    console.log("Quorum");
    console.log(election);

    function handleEventMessage(e){
        console.log("got server sent event");
        const message = JSON.parse(e.data);
        console.log(message);
        if (message.ev){
            switch (message.ev){
                case "voterCheckedIn":
                    console.log(message.data);
                    setCheckedIn(message.data.totalCheckedIn);
                    setVoters(prevVoters => [{name: message.data.name, memberCode: message.data.memberCode}, ...prevVoters] );
                    break;
                default:
                    break;
            }
        }
    }

    useEffect(()=> {
        if (!listening){
            eventSource.current = new EventSource(baseURL + "/event/quorum", {withCredentials: true});
            eventSource.current.onmessage = handleEventMessage;
            setListening(true);

        }
    }, [listening]);

    useEffect(() => {
        if (election.state === "in progress"){
            navigateElectionPositions();
        }
        Api.get("/election/checkedInCount/" + election._id).then((res) =>{
            setCheckedIn(res.data.count);
        });
    }, []);

    function navigateElectionPositions(){
        history.push({
            pathname: '/electionPositions',
            state: {election: election, name: name, role: role}
        });
    }

    function beginVoting(){
        console.log("beginVoting");
        console.log(election);
        Api.get("/election/finishCheckin/" + election._id)
        .then(res => {
            navigateElectionPositions();
        }).catch(err => {
            alert(err);
        });
    }

    function handleBeginVotingClicked(ev){
        setDialogSettings({title: 'Begin the voting process?', 
        message: 'Are you sure you want to begin the voting process? Any additional voters will be unable to join the election after this.',
        ok: 'Yes', cancel: 'No', onCancel: ()=>{setOpen(false)}, onOk: beginVoting });
        setOpen(true);
    }

    function cancelElection(){
        Api.get("/election/cancel/" + election._id)
        .then(res => {
            history.push({
                pathname: '/elections',
                state: {election: election}
            });
        }).catch(err => {
            alert(err);
        });
    }

    function handleCancelClicked(ev){
        setDialogSettings({title: 'Cancel current election?', 
        message: 'Are you sure you want to cancel current election?',
        ok: 'Yes', cancel: 'No', onCancel: ()=>{setOpen(false)}, onOk: cancelElection });
        setOpen(true);
    }

    function resetCheckedIn(){
        Api.get("/election/resetVoters/" + election._id)
        .then(res => {
            setOpen(false);
        }).catch(err => {
            alert(err);
        });        
    }

    function handleResetCheckedInClicked(ev){
        setDialogSettings({title: 'Reset voter check-ins?', 
        message: 'Are you sure you want to reset all voter check-ins?  All the voters will have to check-in again to continue voting.',
        ok: 'Yes', cancel: 'No', onCancel: ()=>{setOpen(false)}, onOk: resetCheckedIn });
        setOpen(true);
    }

    return <React.Fragment>
        <CssBaseline />

        <ConfirmationDialog open={open} title={dialogSettings.title} message={dialogSettings.message}
        ok={dialogSettings.ok} cancel={dialogSettings.cancel} onOk={dialogSettings.onOk}
        onCancel={dialogSettings.onCancel} />


        <AppBar position="sticky">
            <Toolbar>
                <Grid container spacing={3} alignItems="center" justifyContent="space-evenly">
                    <Grid item xs={12} md={12}>
                        <Card alignItems="center">
                            <h2>Welcome to {election.jamaatCode} {election.electionYear} {election.auxiliary} {election.electionType} elections</h2>
                            <Fab color="error" variant="extended" size="large" onClick={handleBeginVotingClicked}><HowToVoteIcon /> Begin Voting</Fab>
                            <Fab color="secondary" variant="extended" size="large" onClick={handleCancelClicked} endIcon={<BackspaceIcon />}>Cancel Election</Fab>
                            <Fab color="error" variant="extended" size="large" onClick={handleResetCheckedInClicked} endIcon={<BackspaceIcon />}>Reset Checked In</Fab>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={12}>
                            <Stack direction="row" spacing={2}>
                            </Stack>
                    </Grid>




                </Grid>
            </Toolbar>
        </AppBar>
        <Toolbar>
            <Grid container spacing={0} paddingTop={0}>
                <Grid item xs={9} md={9}>
                    <h2>Please go to</h2>
                    <h1>{window.location.protocol + "//" + window.location.host}</h1>
                </Grid>
                <Grid item xs={9} md={9}>
                    <h2>Election Login Code</h2>
                    <h1>{election.electionCode}</h1>
                </Grid>
                <Grid item xs={9} md={9}>
                    <h3>Quorum Status</h3>
                    <h1>{checkedIn} of {election.quorumRequired}</h1>
                    <h3>checked in</h3>
                </Grid>
                <Grid item xs={3} md={3}>
                    <Box height={100}>
                        <h4>Recent checked in</h4>
                        <List>
                            {voters.map((v) => {
                                return <ListItem>
                                    <ListItemText key={v.memberCode}>
                                        {v.name}
                                    </ListItemText>
                                </ListItem>
                            })}
                        </List>
                    </Box>
                </Grid>
            </Grid>
        </Toolbar>
    </React.Fragment>;
}