import React, {useState, useEffect, useRef} from "react";
import { AppBar, Toolbar, List, ListItem, ListItemAvatar, Avatar, Grid, Box, Typography, ListItemButton } from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import ConfirmationDialog from '../components/ConfirmationDialog';

import Api, {baseURL} from "../Utils/Api";
import VoteChairSection from "../components/VoteChairSection";

export default function Assistant(props){

    const location = useLocation();
    //const history = useHistory();

    //console.log(props);
    //console.log(location);

    const [showVotes, setShowVotes] = useState(false);
    const [listening, setListening] = useState(false);
    const [nomination, setNomination] = useState({});
    const [section, setSection] = useState("");
    const [position, setPosition] = useState({name: "", title: "", votesPerPerson: 1});
    const [election, setElection] = useState({jamaatCode: ""}); //list of votes the user is required to cast because of nominating or seconding someone
    const [votes, setVotes] = useState([]);
    const [dialogSettings, setDialogSettings] = useState({title: '', message: '', onOk: function(){}, onCancel: function(){}, ok: 'OK', cancel: 'Cancel' });
    const [open, setOpen] = useState(false);

    const events = useRef();


    function handleEventMessage(e){
        console.log("got server sent event");
        const message = JSON.parse(e.data);
        console.log(message);
        if (message.ev){
            switch (message.ev){
                case "beginVoting":
                    console.log(message.data);
                    setNomination(message.data.nomination);
                    setPosition(message.data.position);
                    setVotes(message.data.votes);
                    setShowVotes(true);
                    break;
                default:
                    break;
            }
        }
    }

    function handleChairSelected(section, row, column, checked){
        if (checked){
            Api.post("/assistant/vote", {section: section, row: row, column: column, nominationId: nomination._id, electionPositionId: position._id, position: position.name, nomineeMemberCode: nomination.memberCode, nomineeName: nomination.memberName})
            .then((res)=> {})
            .catch((err)=>{
                alert(err);
            });    
        } else {
            ///:electionPositionId/:nominationId/:section/:row/:col/:memberCode
            Api.delete("/assistant/vote/" + position._id + "/" + nomination._id + "/" + section + "/" + row + "/" + column + "/" + nomination.memberCode)
            .then((res)=> {

            })
            .catch((err)=>{
                alert(err);
            });
        }

        return true;
    }

    useEffect(()=>{
        console.log("election 1");
        console.log(election);
        if (location.state.election){
            setElection(location.state.election);
        }

        console.log("election 2");
        console.log(election);

        if (!election || !election.id){
            Api.get("/assistant/refresh").then((res) => {
                if (res.data.msg && res.data.msg === "ok"){
                    console.log("refresh response");
                    console.log(res.data);
                    setElection(res.data.election);
                    setSection(res.data.section);
                    if (res.data.state === "voting"){
                        setPosition(res.data.position);
                        setNomination(res.data.nomination);
                        setVotes(res.data.votes);
                        setShowVotes(true);
                    }else{
                        setShowVotes(false);
                    }
                    
                }
            });
        }
        
    }, []);

    useEffect(()=> {
        if (!listening){
            events.current = new EventSource(baseURL + "/event/assistant", {withCredentials: true});
            events.current.onmessage = handleEventMessage;
            setListening(true);
        }
    }, [listening]);

 
    return <React.Fragment>
        <ConfirmationDialog open={open} title={dialogSettings.title} message={dialogSettings.message}
        ok={dialogSettings.ok} cancel={dialogSettings.cancel} onOk={dialogSettings.onOk}
        onCancel={dialogSettings.onCancel} width={200} />
        <AppBar color="default" sx={{padding:1, alignContent: "center", alignItems: "center"}} position="sticky">
            <Toolbar>
                <h3>{nomination.memberName} for {position.name} </h3>
            </Toolbar>
        </AppBar>
        <Toolbar>
            { ( showVotes ) &&
                <React.Fragment>
                    <VoteChairSection chairCount={election.chairsPerSection} colCount={election.columnsPerSection} name={section} parentNotify={handleChairSelected} voteData={votes} memberCode={nomination.memberCode} maxVotes={position.votesPerPerson} ></VoteChairSection>
                </React.Fragment>
            }

            {
                (!showVotes) &&
                <Grid container>
                    <Grid item>
                        <Box>
                            <Typography variant="h6">
                                You are taking part in <br/> {election.electionYear} election for <br/> {election.jamaatCode} {election.auxiliary} <br/>
                                please keep this window open <br/> your voting choices will automatically appear here
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            }
        </Toolbar>
    </React.Fragment>
}
